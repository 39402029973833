<template>
  <div class="help-page">
    <common-header></common-header>
    <div class="help-content">
        <!-- 黑色占位盒子 -->
        <div class="help-header"></div>
        <div class="help-body">
            <!-- 合作邮箱 -->
            <div class="partner-mailbox">
                <img :src="$http + '/zh/help/email.png'" />
                <div class="contact-information">
                    <div class="email-box">
                        <div>分销合作：qinstrings@longsqin.com</div>
                        <div>市场合作：qinstrings@longsqin.com</div>
                        <div>商务洽谈：qinstrings@longsqin.com</div>
                    </div>
                    <div class="wechat-box">
                        <div class="wechat-font">
                            <div class="top-back" :style="{'background':'url(' + $http + '/zh/help/wechatFontBack.png) no-repeat'}">微信扫描</div>
                            <div class="bottom-back" :style="{'background':'url(' + $http + '/zh/help/wechatFontBack.png) no-repeat'}">立即开启合作</div>
                        </div>
                        <div class="wechat-img"  :style="{'background':'url(' + $http + '/zh/help/wechatBack.png)'}">
                            <!-- <img src="https://upload.wikimedia.org/wikipedia/commons/f/f2/%E7%BB%B4%E5%9F%BA%E4%B8%AD%E6%96%87%E7%A4%BE%E5%8A%A0%E7%BE%A4%E4%BA%8C%E7%BB%B4%E7%A0%81.png" /> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- 售后服务 -->
            <div class="after-sale">
                <img :src="$http + '/zh/help/afterSale.png'" /> 
                <div>
                    <span>如您在购买后，存在软件下载、安装及序列号验证等问题，请联系官方售后邮箱：service@longsqin.com。</span>
                </div>
            </div>
            <!-- 特此声明 -->
            <div class="statement-box">
                <img :src="$http + '/zh/help/statement.png'" /> 
                <div>
                    <span>分销商做出的除产品官方保证之外的其他承诺，北京珑巳飞扬科技有限公司不承担任何责任。</span>
                </div>
                <div>
                    <span>如有其他疑问，请与官方售后取得联系，官方售后邮箱：service@longsqin.com。</span>
                </div>
            </div>
        </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import commonFooter from "../../components/zh/commonFooter.vue";
import commonHeader from "../../components/zh/commonHeader.vue";
export default {
  components: {
    commonHeader,
    commonFooter
  },
};
</script>

<style scoped lang="scss">
.help-page{
  width: 100%;
  height: 100%;
  .help-content{
    position: relative;
    width: 100%;
    height: 100%;
    // height: 755px;
    // 占位盒子
    .help-header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 90px;
        background: #000;
    }
    .help-body{
        padding: 114px 462px 0 253px;
        box-sizing: border-box;
        // 合作邮箱
        .partner-mailbox{
            img{
                width: 225px;
                height: 84px;
            }
            .contact-information{
                padding-left: 106px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .email-box{
                    font-size: 20px;
                    font-family: PingFang SC;
                    color: #000;
                    line-height: 28px;
                    div{
                        margin-top: 20px;
                    }
                }
                .wechat-box{
                    margin-top: 20px;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    .wechat-font{
                        font-size: 26px;
                        font-family: Source-KeynoteartHans, Source-KeynoteartHans;
                        color: #000000;
                        line-height: 33px;
                        text-align: center;
                        margin-right: 24px;
                        div{
                            padding-bottom: 2px;
                            padding-left: 20px;
                            
                        }
                        .top-back{
                            background-repeat: no-repeat !important;
                            background-position: 42px 100% !important;
                        }
                        .bottom-back{
                            background-repeat: no-repeat !important;
                            background-position: -20% 100% !important;
                        }
                    }
                    .wechat-img{
                        width: 120px;
                        height: 120px;
                        background-repeat: no-repeat !important;
                        background-size: 100% 100% !important;
                        img{
                            margin: 9.5px 0 0 9.5px;
                            width: 101px;
                            height: 101px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
        // 售后服务
        .after-sale{
            margin-top: 50px;
            img{
                width: 223px;
                height: 84px;
                margin: 0;
            }
            div{
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                color: #000000;
                line-height: 28px;
                margin: 24px 0 20px 0px;
                span{
                    margin-left: 106px;
                }
            }
        }
        .statement-box{
            margin-bottom: 121px;
            img{
                width: 223px;
                height: 84px;
                margin: 0;
            }
            div{
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                color: #000000;
                line-height: 28px;
                margin: 24px 0 20px 0px;
                span{
                    margin-left: 106px;
                }
            }
        }
    }
  }
}
</style>